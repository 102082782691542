var Contact = {};

$(function() {
	$('.nav-expander').on('click', function(e) {
		e.preventDefault();
		let $this = $(this),
			$submenu_w = $this.parent().parent().find('.sub-menu-wrapper').first(),
			h = $submenu_w.find('.sub-menu').outerHeight(),
			$submenu_p = false;

		if ($submenu_w.hasClass('subsub')) {
			$submenu_p = $submenu_w.parent().parent().parent();
		}

		if ($submenu_w.attr('aria-expanded') == 'false') {
			$submenu_w.css('max-height', h + 'px').attr('aria-expanded','true');
			$this.attr('data-open', true);

			if ($submenu_p) {
				let parent_height = window.parseInt($submenu_p.css('max-height'));
				$submenu_p.css('max-height', parent_height + h +'px');
			}

			$('body').addClass('locked');
		}
		else {
			$submenu_w.removeAttr('style').attr('aria-expanded','false');
			$this.attr('data-open', false);
			$('body').removeClass('locked');
		}
	});

	if ($('#wcn-language-toggle').length) {
		let activeLang = $('#wcn-language-toggle [selected]').attr('data-lang'),
			langUrl = $('#wcn-language-toggle').val();

		$('.wcn-language-active').text(activeLang);
		$('.language-toggle').addClass('visible');

		$('#wcn-language-toggle').on('change', function(e) {
			e.preventDefault();
			let activeLang = $('#wcn-language-toggle [selected]').attr('data-lang');
			$('.wcn-language-active').text(activeLang);
			window.location.href = $(this).val();
		});
	}

	if ($('.home-hero').length) {
		var hero_slider = new Swiper('.home-hero-slider', {
			autoplay: {
				delay: 4000,
				disableOnInteraction: true,
				pauseOnMouseEnter: true
			},
			loop: true,
			navigation: {
				nextEl: '.home-hero-slider .swiper-next',
				prevEl: '.home-hero-slider .swiper-prev'
			},
			simulateTouch: false,
			watchOverflow: true,
			on: {
				init: function() {
					if (this.slides.length == 1) {
						this.navigation.$nextEl.addClass('none');
						this.navigation.$prevEl.addClass('none');
						this.allowTouchMove = false;
						this.autoplay.stop();
					}
				},

				resize: function() {
					if (this.slides.length == 1) {
						this.navigation.$nextEl.addClass('none');
						this.navigation.$prevEl.addClass('none');
						this.allowTouchMove = false;
						this.autoplay.stop();
					}
				}
			}
		});
	}

	if ($('.product-cards-wrapper.slider').length) {
		var home_products = new Swiper('.product-cards-wrapper.slider .product-cards', {
			autoplay: {
				delay: 4000,
				disableOnInteraction: true,
				pauseOnMouseEnter: true
			},
			loop: true,
			navigation: {
				nextEl: '.product-cards .swiper-next',
				prevEl: '.product-cards .swiper-prev'
			},
			simulateTouch: false,
			watchOverflow: true
		});
	}

	if ($('.blade-slider').length) {
		var blade_slider = new Swiper('.blade-slider', {
			autoplay: {
				delay: 4000,
				disableOnInteraction: true,
				pauseOnMouseEnter: true
			},
			loop: true,
			navigation: {
				nextEl: '.blade-slider .swiper-next',
				prevEl: '.blade-slider .swiper-prev'
			},
			on: {
				init: function() {
					if (this.loopedSlides == 1) {
						this.navigation.$nextEl.addClass('none');
						this.navigation.$prevEl.addClass('none');
						this.allowTouchMove = false;
						this.autoplay.stop();
					}
				},

				resize: function() {
					if (this.loopedSlides == 1) {
						this.navigation.$nextEl.addClass('none');
						this.navigation.$prevEl.addClass('none');
						this.allowTouchMove = false;
						this.autoplay.stop();
					}
				}
			},
			simulateTouch: false,
			watchOverflow: true
		});
	}

	$('div .video-wrapper').on('click', function(e) {
		e.preventDefault();

		if ($(this).find('.default-btn a').length) {
			let link = $(this).find('.default-btn a');
			Modal.open(link);
		}
	});

	if ($('.usalo-insieme-slider').length) {
		var hero_slider = new Swiper('.usalo-insieme-slider', {
			autoplay: {
				delay: 4000,
				disableOnInteraction: true,
				pauseOnMouseEnter: true
			},
			loop: true,
			navigation: {
				nextEl: '.usalo-insieme-slider .swiper-next',
				prevEl: '.usalo-insieme-slider .swiper-prev'
			},
			simulateTouch: false,
			watchOverflow: true,
			breakpoints: {
				1024: {
					spaceBetween: 16,
					slidesPerView: 3
				}
			}
		});
	}

	if ($('.stessa-linea-slider').length) {
		var hero_slider = new Swiper('.stessa-linea-slider', {
			autoplay: {
				delay: 4000,
				disableOnInteraction: true,
				pauseOnMouseEnter: true
			},
			loop: true,
			navigation: {
				nextEl: '.stessa-linea-slider .swiper-next',
				prevEl: '.stessa-linea-slider .swiper-prev'
			},
			simulateTouch: false,
			spaceBetween: 32,
			watchOverflow: true,
			breakpoints: {
				1024: {
					spaceBetween: 16,
					slidesPerView: 3
				}
			}
		});
	}

	$('.concertina dt').on('click', function(e) {
		e.preventDefault();
		let pid = $(this).attr('id').replace('control-', ''),
				th = $('#panel-'+ pid +' .panel-wrapper').height();

		if ($('#panel-'+ pid).attr('aria-expanded') == 'false') {
			$('#panel-'+ pid).attr('aria-expanded', 'true').css('max-height', (th + 8) +'px');
			$('#control-'+ pid).addClass('open');
		}
		else {
			$('#panel-'+ pid).attr('aria-expanded', 'false').removeAttr('style');
			$('#control-'+ pid).removeClass('open');
		}
	});

	$(window).on('load', function() {
		$('.concertina dt').each(function(e) {
			if ($(this).hasClass('open')) {
				let pid = $(this).attr('id').replace('control-', ''),
					th = $('#panel-'+ pid +' .panel-wrapper').height();
				$('#panel-'+ pid).attr('aria-expanded', 'true').css('max-height', (th + 8) +'px');
			}
		});
	});

	if ($('body').hasClass('page_contattaci') || $('body').hasClass('page_contact-us')) {
		$('#contactForm select').Selecta({
			class: 'select-menu',
			nativeOnMobile: true,
			validateOnSubmit: true
		});
	}

	if ($('#contactForm').length) {
		Contact.init();
	}
});

Contact.init = function() {
	$('#contactForm').on('submit', function(e) {
		e.preventDefault();
		if (Contact.validateUserData()) {
			var formData = $('#contactForm').serialize();
			$('.form-wrapper').addClass('disabled').addClass('waiting');
			$('.form-submit').attr('disabled', true);
			$.ajax({
				url: '/wp-admin/admin-ajax.php',
				method: 'post',
				data: 'action=ob_contact&'+ formData
			}).done(function(xhr) {
				var response = JSON.parse(xhr);
				if (response.status == 'OK') {
					$('.form-wrapper').removeClass('waiting');
					$('#formResponsePositive').show().css('opacity', 1);
				}
				else {
					$('.form-wrapper').removeClass('waiting');
					$('#formResponseError').show().css('opacity', 1);
				}
			});
		}
	});
}

Contact.validateUserData = function() {
	var form = $('#contactForm'),
		valid = true;

	if ($('#Nome').val().trim().length < 1) {
		Contact.error($('#Nome'), 'show');
		valid = false;
	}
	else {
		Contact.error($('#Nome'), 'hide');
	}

	if ($('#Indirizzo').val().trim().length < 1) {
		Contact.error($('#Indirizzo'), 'show');
		valid = false;
	}
	else {
		Contact.error($('#Indirizzo'), 'hide')
	}

	if ($('#Civico').val().trim().length < 1) {
		Contact.error($('#Civico'), 'show');
		valid = false;
	}
	else {
		Contact.error($('#Civico'), 'hide');
	}

	if ($('#Citta').val().trim().length < 1) {
		Contact.error($('#Citta'), 'show');
		valid = false;
	}
	else {
		Contact.error($('#Citta'), 'hide');
	}

	if ($('#CAP').val().trim().length < 1) {
		Contact.error($('#CAP'), 'show');
		valid = false;
	}
	else {
		Contact.error($('#CAP'), 'hide');
	}

	if ($('#Provincia').val() == null || $('#Provincia').val() == 'null') {
		Contact.error($('#Provincia').parent().parent(), 'show');
		valid = false;
	}
	else {
		Contact.error($('#Provincia').parent().parent(), 'hide');
	}

	if ($('#Prodotto').val() == null || $('#Prodotto').val() == 'null') {
		Contact.error($('#Prodotto').parent().parent(), 'show');
		valid = false;
	}
	else {
		Contact.error($('#Prodotto').parent().parent(), 'hide');
	}

	if ($('#Eta').val() == null || $('#Eta').val() == 'null') {
		Contact.error($('#Eta').parent().parent(), 'show');
		valid = false;
	}
	else {
		Contact.error($('#Eta').parent().parent(), 'hide');
	}

	if (!$('#sesso_m').is(':checked') && !$('#sesso_f').is(':checked')) {
		Contact.error($('.radio-group'), 'show');
		valid = false;
	}
	else {
		Contact.error($('.radio-group'), 'hide');
	}

	if ($('#Commento').val().trim().length < 1) {
		Contact.error($('#Commento'), 'show');
		valid = false;
	}
	else {
		Contact.error($('#Commento'), 'hide');
	}

	if (!$('#Privacy').is(':checked')) {
		Contact.error($('.checkbox-item'), 'show');
		valid = false;
	}
	else {
		Contact.error($('.checkbox-item'), 'hide');
	}

	return valid;
}

Contact.error = function($el, status) {
	if ($el.length && status == 'show') {
		$el.parent().find('.field-error').html('<div class="msg">Si prega di compilare questo campo.</div>').addClass('show');
	}
	else if ($el.length && status == 'hide') {
		$el.parent().find('.field-error').removeClass('show').empty();
	}
}